import './App.css';
// @ts-ignore
import { DuckSayPWA } from "@ducksay/ducksay-pwa"
import { useEffect, useState } from "react";


function App() {
  const [isInstalled, setIsInstalled] = useState(false);
  const [isInstallChecked, setIsInstallChecked] = useState(false);
  const lastInstall = localStorage.getItem('pwaLastInstall');

  if(lastInstall) {
    const lastInstallDate = new Date(parseInt(lastInstall));
    const currentDate = new Date();
    const diff = currentDate.getTime() - lastInstallDate.getTime();
    if(diff < 1000 * 60 * 60 * 24) {
      localStorage.clear();
    }
  }

  useEffect(() => {
    const checkIfInstalled = () => {
      const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
      const isIOSStandalone = (window.navigator as any).standalone === true;
      setIsInstalled(isStandalone || isIOSStandalone);
      setIsInstallChecked(true);
    };

    checkIfInstalled();
    window.addEventListener("resize", checkIfInstalled);

    return () => {
      window.removeEventListener("resize", checkIfInstalled);
    };
  }, []);

  useEffect(() => {
    const checkIfUninstalled = async () => {
    if (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad')) {
      // iOS specific logic
      const isStandalone = (window.navigator as any).standalone === true;
      if (!isStandalone) {
        localStorage.clear();
      }
    } else {
      // Other platforms
      const registrations = await navigator.serviceWorker?.getRegistrations();
      if (registrations.length === 0) {
        localStorage.clear();
      }
    }
  };
    checkIfUninstalled();
  }, []);

  useEffect(() => {
    if (!isInstallChecked) {
      return;
    }

    if (isInstalled) {
      const pwaStorage = localStorage.getItem("pwaStorage");

      if (pwaStorage) {
        const parsedPwa = JSON.parse(pwaStorage);
        localStorage.setItem("pwaStorage", JSON.stringify({ ...parsedPwa, isInstalled: true }));

        window.location.replace(parsedPwa?.blackLink || parsedPwa.offerLink);
        return;
      }
    }
  }, [isInstalled, isInstallChecked]);


  if (!isInstallChecked || isInstalled) {
    return null;
  }

  return <DuckSayPWA />;
}

export default App;

