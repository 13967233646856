import React from "react";

function ImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#B4B1B9"
        d="M2.562 23.988a2.46 2.46 0 01-1.809-.74 2.46 2.46 0 01-.74-1.81V2.564c0-.712.246-1.315.74-1.809a2.46 2.46 0 011.81-.74h18.875a2.46 2.46 0 011.809.74 2.46 2.46 0 01.74 1.81v18.875a2.46 2.46 0 01-.74 1.809 2.46 2.46 0 01-1.81.74H2.563zm0-2.115h18.876a.414.414 0 00.298-.136c.09-.09.136-.19.136-.298V2.563a.414.414 0 00-.136-.298.414.414 0 00-.298-.136H2.562a.414.414 0 00-.298.136.414.414 0 00-.136.298V21.44c0 .108.045.208.136.298.09.09.19.136.298.136zM4.596 18.7h14.916l-4.637-6.184-3.96 5.153-2.82-3.607L4.596 18.7zm2.468-9.872c.488 0 .904-.172 1.247-.516.344-.343.516-.759.516-1.247 0-.488-.172-.904-.516-1.247a1.698 1.698 0 00-1.247-.516c-.488 0-.904.172-1.247.516-.344.343-.516.759-.516 1.247 0 .488.172.904.516 1.247.343.344.759.516 1.247.516z"
      ></path>
    </svg>
  );
}

export default ImageIcon;
