import { IPwaData } from "../interfaces";
import { textsToTranslate } from '../utils/translationServise';

export const pwaMockData: IPwaData = {
  creatives: {
    icon: "https://ducksay.s3.eu-central-1.amazonaws.com/creatives/icons/82cf7d28-a7d0-41ea-9e4e-cc37ee1960f9_54.jpg",
    previews: [
      // "https://ducksay.s3.eu-central-1.amazonaws.com//previews/17c1ac69-c54e-4cf5-bf46-e9eedc2b67e1__r0x408_webp_(1).webp",
      // "https://ducksay.s3.eu-central-1.amazonaws.com//previews/17c1ac69-c54e-4cf5-bf46-e9eedc2b67e1__r0x408_webp_(1).webp",
      "https://ducksay.s3.eu-central-1.amazonaws.com/creatives/previews/825341b7-ddfc-4446-8f10-c2e55474a76d_54.1.jpg",
      "https://ducksay.s3.eu-central-1.amazonaws.com/creatives/previews/825341b7-ddfc-4446-8f10-c2e55474a76d_54.2.jpg",
      "https://ducksay.s3.eu-central-1.amazonaws.com/creatives/previews/825341b7-ddfc-4446-8f10-c2e55474a76d_54.3.jpg",
    ],
  },
  _id: "66b3d518d113ae8d6ca39734",
  appName: "Tramp Donald",
  author: "Donald",
  ad: true,
  category: ["Casino", "Gambling"],
  size: "17",
  description: "Lorem, ipsum dolor sit amet consectetur adipisicing elit.",
  dateOfUpdates: "10/10/2024",
  numberOfInst: "7000",
  developerSite: "developer.com",
  developerEmail: "developer@email.com",
  adressCompany: "M16F, Dongwoo Bldg, 328, Teheran-ro, Gangnam-gu, Seoul, 06212, Rep. of KOREA",
  rating: "3.7",
  amountReviews: "1500",
  ratings: [1170, 123, 141, 34, 32],
  descriptionPC: "Description for pc",
  userId: "66741dc03116a8871942fd5b",
  linkPolicy: "Privacy policy",
  language: "en-GB",
  country: "Australia",
  status: "active",
  offerLink: "https://google.com",
  categoryTracking: "",
  trafficBack: "",
  getParams: true,
  filesCookies: true,
  fullscreen: false,
  ignoringCloaca: false,
  geo: ["[]"],
  inappropriateTraffic: "",
  wightSource: "",
  linkRedirect: "",
  sendAnoutherGeo: false,
  onlyAndroid: false,
  token: "",
  categoryVertical: "Gambling",
  youtubeVideoLink: "https://link-video-youtube",
  soundVideo: false,
  comments: [
    {
      author: "Grace Wright",
      comment: "User interface needs improvement. Not satisfied.",
      rating: 5,
      date: "2022-09-09T16:30:00Z",
      avatar: "",
      _id: "66b0c70a169debb63b157e9d",
    },
    {
      author: "Sophia Martinez",
      comment: "User interface needs improvement. Not satisfied.",
      rating: 3,
      date: "2023-03-17T08:00:00Z",
      avatar: "",
      _id: "66b0c70a169debb63b157e9e",
    },
  ],
  createdAt: "2024-08-07T20:12:08.731Z",
  updatedAt: "2024-08-30T17:31:47.739Z",
  price: "0.09",
  domain: null,
  osId: null,
  translations: textsToTranslate,
};
