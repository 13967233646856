function parseQueryString(queryString: string): Record<string, string> {
  const params = new URLSearchParams(queryString);
  const result: Record<string, string> = {};

  params.forEach((value, key) => {
    result[key] = value;
  });

  return result;
}

export const updateOfferLinkWithGetParams = (data: any) => {
  if (!data) return data;
  const newPWAData = { ...data };
  const pwaID = data._id;

  const offerSearchParams = data.offerLink.split("?")[1];
  const blackSearchParams = data.blackLink.split("?")[1];

  const blacklink = data.blackLink || data.offerLink;
  newPWAData.offerLink = `${data.offerLink}${offerSearchParams ? "&" : "?"}pwa_id=${pwaID}`;
  newPWAData.blackLink = `${blacklink}${blackSearchParams ? "&" : "?"}pwa_id=${pwaID}`;

  if (data?.getParams) {
    const params = parseQueryString(window.location.search);
    const getParams = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    newPWAData.offerLink = getParams.length ? `${newPWAData.offerLink}&${getParams}` : newPWAData.offerLink;
    newPWAData.blackLink = getParams.length ? `${newPWAData.blackLink}&${getParams}` : newPWAData.blackLink;
  }
  return newPWAData;
};
